import React, { Fragment, useEffect, useState } from 'react';
import Header from '../components/Header';
import axios from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import styled from 'styled-components';
import AnalysisChart from '../components/Chart';
import { BounceLoader } from 'react-spinners';

interface collectedDataInterface {
  bp: [
    {
      bpid: number;
      uid: number;
      sys: number;
      dia: number;
      pulse: number;
      time_of_check: string;
    }
  ];
  bs: [
    {
      bsid: number;
      uid: number;
      glucose: number;
      time_of_check: string;
    }
  ];
  hw: [
    {
      hwid: number;
      uid: number;
      height: number;
      weight: number;
      time_of_check: string;
    }
  ];
}

const AnalysisContainer = styled.div<{ selectedmenuprop: number }>`
  /* display: flex;
  justify-content: center; */

  .spinner {
    top: 0 !important;
    left: 0;
    background-color: #ebd5ac;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }

  .analysisMenuWrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .analysisMenuSelector {
    border-radius: 10px;
    border: 1px solid black;
    margin: 0.1rem;
    padding: 1rem 0.5rem;
    text-align: center;
  }

  .firstSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 1 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 1 ? 'white' : 'black')};
  }
  .secondSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 2 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 2 ? 'white' : 'black')};
  }
  .thirdSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 3 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 3 ? 'white' : 'black')};
  }

  .analysisButton {
    border-radius: 10px;
    width: 100px;
    border: 1px solid black;
    margin: 0.1rem;
    padding: 1rem 0.5rem;
    text-align: center;
  }

  .analyzedData{
    margin: 0.1rem;
    padding: 1rem 0.5rem;
    white-space: pre-line;
  }
`;

function Analysis() {
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [collectedData, setCollectedData] = useState<collectedDataInterface>();
  const [analyzedData, setAnalyzedData] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (user.name !== 'guest') {
      collectedDataHandler(user);
    }
  }, [user]);

  useEffect(() => {
    setAnalyzedData('');
  }, [selectedMenu]);

  // useEffect(() => {
  //   console.log(collectedData.data.bp[0][0].uid);
  // }, [collectedData]);

  const collectedDataHandler = async (data: object) => {
    await axios
      .post('/data/collected', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response);
        setCollectedData(response.data.data);
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const analysisDataHandler = async (data: object) => {
    const selectedData =
      selectedMenu === 1
        ? collectedData!.bp
        : selectedMenu === 2
        ? collectedData!.hw
        : collectedData!.bs;
    setLoading(true);

    await axios
      .post('/data/advice', selectedData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log('analysisDataHandler', response);

        setAnalyzedData(response.data.data.choices[0].message.content);
        setLoading(false);
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const filteredData: { x: Date; y: any }[] = [];
  useEffect(() => {
    collectedData !== undefined &&
      collectedData.bp.forEach((element: any) => {
        filteredData.push({
          x: new Date(element.time_of_check),
          y: element.sys,
        });
      });
  });

  return (
    <AnalysisContainer selectedmenuprop={selectedMenu}>
      {loading ? (
        <div className="spinner">
          <BounceLoader />
        </div>
      ) : (
        <Fragment>
          <Header />
          <div>
            <div className="analysisMenuWrapper">
              <div
                className="analysisMenuSelector firstSelector cp"
                onClick={() => setSelectedMenu(1)}
              >
                Blood Pressure
              </div>
              <div
                className="analysisMenuSelector secondSelector cp"
                onClick={() => setSelectedMenu(2)}
              >
                Height & Weight
              </div>
              <div
                className="analysisMenuSelector thirdSelector cp"
                onClick={() => setSelectedMenu(3)}
              >
                Blood Sugar
              </div>
            </div>
            {selectedMenu === 1 && (
              <AnalysisChart
                title={'Blood Pressure'}
                type={'bp'}
                yaxis={['SYS', 'DIA', 'Pulse']}
                collectedData={collectedData !== undefined && collectedData.bp}
              />
            )}
            {selectedMenu === 2 && (
              <AnalysisChart
                title={'Height & Weight'}
                type={'hw'}
                yaxis={['Height', 'Weight']}
                collectedData={collectedData !== undefined && collectedData.hw}
              />
            )}
            {selectedMenu === 3 && (
              <AnalysisChart
                title={'Blood Sugar'}
                type={'bs'}
                yaxis={['Glucose']}
                collectedData={collectedData !== undefined && collectedData.bs}
              />
            )}
          </div>
          <div className="analysisMenuWrapper">
            <div
              className="analysisButton cp"
              onClick={() => analysisDataHandler(collectedData!)}
            >
              Analysis
            </div>
          </div>
          <div className="analysisMenuWrapper">
            <div className="analyzedData">{analyzedData}</div>
          </div>
        </Fragment>
      )}
    </AnalysisContainer>
  );
}

export default Analysis;
